// export const nftAddress = "0xcc81614cf757e91F6dD0eA0C8082f7f2EE3b9522"; // Ethereum mainnet LOOKSDAO

// export const tokenAddress = "0xf4d2888d29d722226fafa5d9b24f9164c092421e"; // Ethereum mainnet LOOKS


export const nftAddress = "0x17B875d8DDf189c923b39adF5de8D8dbc8223DDC"; // Bsc testnet

export const tokenAddress = "0xB800d4A8003FCB480e6445832cE4Cf5A6259A7c2"; // Bsc testnet


export const chainId = "0x38"; //BSC Mainnet
/* export const chainId = "0x1"; //BSC Mainnet */